<template>
  <div>
    <CompanyInfo
      :images="images"
      :companyBrandColour="companyBrandColour"
      :companyLogo="companyLogo"
    >
      <template #companyName>Sandoz</template>
      <template #date>2014 - 2018</template>
      <template #content>
        <ul class="dashed">
          <li>
            Supported Sandoz for four years by educating and engaging delegates on the use
            of biosimilar drugs.
          </li>
          <li>
            We supplied both our interactive iPad and keypad voting systems for
            approximately 8 European congresses a year.
          </li>
          <li>
            Delegates answered a series of questions where we saved answers and discovered
            any gaps in their use and sent this important information back to Sandoz for
            later analysis.
          </li>
          <li>
            We supplied training on the exhibition booth and provided interactive tools
            for exhibition booth staff, which helped to build team confidence and improve
            communication skills for company representatives.
          </li>
          <li>
            We also facilitated a booth captain that could support the day to day running
            of the exhibition by liaising with representatives and delegates throughout
            time in the exhibition space.
          </li>
        </ul>
      </template>
    </CompanyInfo>
  </div>
</template>

<script>
import CompanyInfo from "@/components/Portfolio/CompanyInfo.vue";
export default {
    name: 'SandozItem',
  components: { CompanyInfo },
  data() {
    return {
      companyBrandColour: "#255BA1",
      companyLogo: require("@/assets/images/portfolio/sandoz-logo.svg"),
      images: [
        {
          id: 0,
          responsiveImages: require("@/assets/images/portfolio/sandoz/1.jpg"),
          position: { x: 50, y: 25 },
          backgroundImageTint: "rgba(0, 0, 0, 0.1)",
          pictureDetails: "EUROPEAN ASSOCIATION OF HOSPITAL PHARMACISTS (2019) BARCELONA",
        },
        {
          id: 1,
          responsiveImages: require("@/assets/images/portfolio/sandoz/2.jpg"),
          position: { x: 50, y: 25 },
          backgroundImageTint: "rgba(0, 0, 0, 0.1)",
          pictureDetails: "EUROPEAN CONGRESS OF RHEUMATOLOGY (2019) MADRID",
        },
        {
          id: 2,
          responsiveImages: require("@/assets/images/portfolio/sandoz/3.jpg"),
          position: { x: 50, y: 25 },
          backgroundImageTint: "rgba(0, 0, 0, 0.1)",
          pictureDetails: "EUROPEAN CONGRESS OF RHEUMATOLOGY (2019) MADRID",
        },
        {
          id: 3,
          responsiveImages: require("@/assets/images/portfolio/sandoz/4.jpg"),
          position: { x: 50, y: 25 },
          backgroundImageTint: "rgba(0, 0, 0, 0.1)",
          pictureDetails: "EUROPEAN CONGRESS OF RHEUMATOLOGY (2019) MADRID",
        },
        {
          id: 4,
          responsiveImages: require("@/assets/images/portfolio/sandoz/5.jpg"),
          position: { x: 50, y: 25 },
          backgroundImageTint: "rgba(0, 0, 0, 0.1)",
          pictureDetails: "EUROPEAN CONGRESS OF RHEUMATOLOGY (2019) MADRID",
        },
        {
          id: 5,
          responsiveImages: require("@/assets/images/portfolio/sandoz/6.jpg"),
          position: { x: 50, y: 25 },
          backgroundImageTint: "rgba(0, 0, 0, 0.1)",
          pictureDetails: "EUROPEAN CROHN’S AND COLITIS ORGANISATION (2018) VIENNA",
        },
        {
          id: 6,
          responsiveImages: require("@/assets/images/portfolio/sandoz/7.jpg"),
          position: { x: 50, y: 25 },
          backgroundImageTint: "rgba(0, 0, 0, 0.1)",
          pictureDetails:
            "EUROPEAN ASSOCIATION OF HOSPITAL PHARMACISTS (2018) GOTHENBURG",
        },
        {
          id: 7,
          responsiveImages: require("@/assets/images/portfolio/sandoz/8.jpg"),
          position: { x: 50, y: 25 },
          backgroundImageTint: "rgba(0, 0, 0, 0.1)",
          pictureDetails: "EUROPEAN HAEMATOLOGY ASSOCIATION (2018) STOCKHOLM",
        },
        {
          id: 8,
          responsiveImages: require("@/assets/images/portfolio/sandoz/9.jpg"),
          position: { x: 50, y: 25 },
          backgroundImageTint: "rgba(0, 0, 0, 0.1)",
          pictureDetails: "EUROPEAN CONGRESS OF RHEUMATOLOGY (2019) MADRID",
        },
        {
          id: 9,
          responsiveImages: require("@/assets/images/portfolio/sandoz/10.jpg"),
          position: { x: 50, y: 25 },
          backgroundImageTint: "rgba(0, 0, 0, 0.1)",
          pictureDetails: "EUROPEAN CONGRESS OF RHEUMATOLOGY (2019) MADRID",
        },
        {
          id: 10,
          responsiveImages: require("@/assets/images/portfolio/sandoz/11.jpg"),
          position: { x: 50, y: 25 },
          backgroundImageTint: "rgba(0, 0, 0, 0.1)",
          pictureDetails: "EUROPEAN CONGRESS OF RHEUMATOLOGY (2019) MADRID",
        },
        {
          id: 11,
          responsiveImages: require("@/assets/images/portfolio/sandoz/12.jpg"),
          position: { x: 50, y: 25 },
          backgroundImageTint: "rgba(0, 0, 0, 0.1)",
          pictureDetails:
            "EUROPEAN ASSOCIATION OF HOSPITAL PHARMACISTS (2019) GOTHENBURG",
        },
      ],
    };
  },
};
</script>
